<template>
  <div>
      <div class="wrap">
          <div class="con_top">
            <h2><span>■</span> 기업 별 통계 - {{ companyName }}</h2>
          </div>
          <div class="con mt-30">
              <div class="con_table">
                  <div class="search2 mb-30">
                       <div>기간검색 :
                           <select name="birth-yyyy" v-model="startYY" class="ml-20" id="birth-yyyy">
                             <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                           </select>
                           <select name="birth-mm" v-model="startMM" class="ml-10" id="birth-mm">
                             <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                           </select>
                           ~
                          <select name="birth-yyyy" v-model="endYY" class="ml-10" id="birth-yyyy">
                            <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                          </select>
                          <select name="birth-mm" v-model="endMM" class="ml-10" id="birth-mm">
                            <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                          </select>
                          <select v-model="idxCrmCompany" class="font-16 w-150px h-40px pl-10">
                            <option value="-1">기업선택</option>
                            <option v-for="(item, index) of companyList" :key="index" :value="item.idx">{{item.name}}</option>
                         </select>
                         <select v-model="idxCrmCenter"  class="font-16 w-150px h-40px pl-10">
                             <option value="-1">센터선택</option>
                             <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{ item.name }}</option>
                         </select>
                         <a class="btn_search pointer" @click="getCompanyStatistic()">Search<span></span></a>
                       </div>
                       <div>
                           <button type="button" class="mt-10 down-btn float-right" @click="excelDownload()">상세 내역 다운로드</button>
                       </div>
                  </div>
              </div>
          </div>

          <div class="con_top">
            <h2 class="title"><span></span>전체 이용 현황</h2>

            <p class="ml-10 mt-10 item-title">
                상담 인원 별 (회 / 명)
            </p>
            <div class="con_table">
                <table class="default_table mt-20 company">
                    <tbody v-if="loading">
                        <tr>
                            <th>총 이용현황</th>
                            <th>신규</th>
                            <th>기존</th>
                        </tr>
                        <tr>
                            <td colspan="3">검색중입니다.</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th>총 이용현황</th>
                            <th>신규</th>
                            <th>기존</th>
                        </tr>
                        <tr>
                            <td>{{ this.companyStatic.totalReportCnt }}회 / {{ this.companyStatic.totalPersonCnt }}명</td>
                            <td>{{ this.companyStatic.newReportCnt  }}회 / {{ this.companyStatic.newPersonCnt  }}명</td>
                            <td>{{ this.companyStatic.oldReportCnt }}회 / {{ this.companyStatic.oldPersonCnt }}명</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>

          <div class="con_top">
            <h2 class="title"><span></span>상담 이용 현황</h2>
            
            <p class="ml-10 mt-10 item-title">
                성 별 (회 / 명)
            </p>
            <div class="con_table">
                <table class="default_table mt-20 company">
                    <tbody v-if="loading">
                        <tr>
                            <th>남자</th>
                            <th>여자</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                            <td colspan="3">검색중입니다.</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th>남자</th>
                            <th>여자</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                           <td>{{ this.companyStatic.countMan}} 회 / {{ this.companyStatic.genderMan  }}명</td>
                           <td>{{ this.companyStatic.countWoman}} 회 / {{ this.companyStatic.genderWoman }}명</td>
                           <td>{{ this.companyStatic.countGenderNone}} 회 / {{ this.companyStatic.genderNone }}명</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p class="ml-10 mt-10 item-title">
                연령 별 (회 / 명)
            </p>
            <div class="con_table">
                <table class="default_table mt-20 company">
                    <tbody v-if="loading">
                        <tr>
                            <th>20대</th>
                            <th>30대</th>
                            <th>40대</th>
                            <th>50대이상</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                            <td colspan="5">검색중입니다.</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th>10대이하</th>
                            <th>20대</th>
                            <th>30대</th>
                            <th>40대</th>
                            <th>50대이상</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                            <td>{{ this.companyStatic.countAge10 }}회 / {{ this.companyStatic.age10 }}명</td>
                            <td>{{ this.companyStatic.countAge20 }}회 / {{ this.companyStatic.age20 }}명</td>
                            <td>{{ this.companyStatic.countAge30 }}회 / {{ this.companyStatic.age30 }}명</td>
                            <td>{{ this.companyStatic.countAge40 }}회 / {{ this.companyStatic.age40 }}명</td>
                            <td>{{ this.companyStatic.countAge50 }}회 / {{ this.companyStatic.age50 }}명</td>
                            <td>{{ this.companyStatic.countAgeNone }}회 / {{ this.companyStatic.ageNone }}명</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p class="ml-10 mt-10 item-title">
                위험 단계 별 (회 / 명) <span class="red">( * 각 단계별 회수를 클릭하면 회수에 대한 상담일지를 확인 할 수 있습니다. )</span>
            </p>
            <div class="con_table">
                <table class="default_table mt-20 company">
                    <tbody v-if="loading">
                        <tr>
                            <th>1단계</th>
                            <th>2단계</th>
                            <th>3단계</th>
                            <th>4단계</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                            <td colspan="5">검색중입니다.</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th>1단계</th>
                            <th>2단계</th>
                            <th>3단계</th>
                            <th>4단계</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                            <td  @click="openDangerListModal(36)"> <span class="pointer"> {{ this.companyStatic.danger1st  }}회 </span> / {{ this.companyStatic.perDanger1st }}명</td>
                            <td class="pointer"  @click="openDangerListModal(37)"> <span class="pointer">{{ this.companyStatic.danger2nd }}회</span>  / {{ this.companyStatic.perDanger2nd }}명</td>
                            <td class="pointer"  @click="openDangerListModal(38)"> <span class="pointer">{{ this.companyStatic.danger3rd  }}회</span> / {{ this.companyStatic.perDanger3rd }}명</td>
                            <td class="pointer"  @click="openDangerListModal(39)"> <span class="pointer">{{ this.companyStatic.danger4th }}회</span>  / {{ this.companyStatic.perDanger4th }}명</td>
                            <td class="pointer"  @click="openDangerListModal(99)"> <span class="pointer"> {{ this.companyStatic.dangerNone }}회</span> / {{ this.companyStatic.perDangerNone }}명</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p class="ml-10 mt-10 item-title">
                상담 형태 별 (회 / 명)
            </p>
            <div class="con_table">
                <table class="default_table mt-20 company">
                    <tbody v-if="loading">
                        <tr>
                            <th>대면</th>
                            <th>화상</th>
                            <th>전화</th>
                            <th>채팅</th>
                            <th>방문상담</th>
                            <th>상주상담</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                            <td colspan="7">검색중입니다.</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th>대면</th>
                            <th>화상</th>
                            <th>전화</th>
                            <th>채팅</th>
                            <th>방문상담</th>
                            <th>상주상담</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                            <td>{{ this.companyStatic.typeFace  }}회 / {{ this.companyStatic.perTypeFace }}명</td>
                            <td>{{ this.companyStatic.typeVideo }}회 / {{ this.companyStatic.perTypeVideo }}명</td>
                            <td>{{ this.companyStatic.typePhone  }}회 / {{ this.companyStatic.perTypePhone }}명</td>
                            <td>{{ this.companyStatic.typeChat }}회 / {{ this.companyStatic.perTypeChat }}명</td>
                            <td>{{ this.companyStatic.typeVisit  }}회 / {{ this.companyStatic.perTypeVisit }}명</td>
                            <td>{{ this.companyStatic.typeReside }}회 / {{ this.companyStatic.perTypeReside }}명</td>
                            <td>{{ this.companyStatic.typeNone }}회 / {{ this.companyStatic.perTypeNone }}명</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p class="ml-10 mt-10 item-title">
                회기 유형 별 (회 / 명)
            </p>
            <div class="con_table">
                <table class="default_table mt-20 company">
                    <tbody v-if="loading">
                        <tr>
                            <th>심리검사</th>
                            <th>개인상담</th>
                            <th>미술/놀이치료</th>
                            <th>기타</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                            <td colspan="5">검색중입니다.</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th>심리검사</th>
                            <th>개인상담</th>
                            <th>미술/놀이치료</th>
                            <th>기타</th>
                            <th>미설정</th>
                        </tr>
                        <tr>
                            <td>{{ this.companyStatic.genrePsy  }}회 / {{ this.companyStatic.perGenrePsy }}명</td>
                            <td>{{ this.companyStatic.genrePerson }}회 / {{ this.companyStatic.genrePersonCnt }}명</td>
                            <td>{{ this.companyStatic.genrePlay  }}회 / {{ this.companyStatic.perGenrePlay }}명</td>
                            <td>{{ this.companyStatic.genreOther }}회 / {{ this.companyStatic.perGenreOther }}명</td>
                            <td>{{ this.companyStatic.genreNone }}회 / {{ this.companyStatic.perGenreNone }}명</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p class="ml-10 mt-10 item-title">
                상담주제 별 (회 / 명)
            </p>
            <div class="con_table pb-50">
                <table class="default_table mt-20 company">
                    <tbody >
                        <tr>
                            <th rowspan="11">직장관련</th>
                            <td>관계 갈등(상사/동료/부하)</td>
                            <td>{{ this.companyStatic.agenda15 }}회 / {{ this.companyStatic.perAgenda15 }}명</td>
                        </tr>
                        <tr>
                            <td>직무스트레스</td>
                            <td>{{ this.companyStatic.agenda16 }}회 / {{ this.companyStatic.perAgenda16 }}명</td>
                        </tr>
                        <tr>
                            <td>급여/승진</td>
                            <td>{{ this.companyStatic.agenda17 }}회 / {{ this.companyStatic.perAgenda17 }}명</td>
                        </tr>
                        <tr>
                            <td>탈진/사기저하</td>
                            <td>{{ this.companyStatic.agenda18 }}회 / {{ this.companyStatic.perAgenda18 }}명</td>
                        </tr>
                        <tr>
                            <td>성희롱/괴롭힘</td>
                            <td>{{ this.companyStatic.agenda19 }}회 / {{ this.companyStatic.perAgenda19 }}명 </td>
                        </tr>
                        <tr>
                            <td>감정노동/악성민원</td>
                            <td>{{ this.companyStatic.agenda20 }}회 / {{ this.companyStatic.perAgenda20 }}명</td>
                        </tr>
                        <tr>
                            <td>조직문화/회사정책</td>
                            <td>{{ this.companyStatic.agenda21 }}회 / {{ this.companyStatic.perAgenda21 }}명</td>
                        </tr>
                        <tr>
                            <td>근무환경/안정성</td>
                            <td>{{ this.companyStatic.agenda22 }}회 / {{ this.companyStatic.perAgenda22 }}명</td>
                        </tr>
                        <tr>
                            <td>도덕성/직업윤리</td>
                            <td>{{ this.companyStatic.agenda23 }}회 / {{ this.companyStatic.perAgenda23 }}명</td>
                        </tr>
                        <tr>
                            <td>경력/진로</td>
                            <td>{{ this.companyStatic.agenda24 }}회 / {{ this.companyStatic.perAgenda24 }}명</td>
                        </tr>
                        <tr>
                            <td>기타</td>
                            <td>{{ this.companyStatic.agenda25 }}회 / {{ this.companyStatic.perAgenda25 }}명</td>
                        </tr>

                        <tr>
                            <th rowspan="11">심리정서</th>
                            <td>대인관계</td>
                            <td>{{ this.companyStatic.agenda26 }}회 / {{ this.companyStatic.perAgenda26 }}명</td>
                        </tr>
                        <tr>
                            <td>우울/불안</td>
                            <td>{{ this.companyStatic.agenda27 }}회 / {{ this.companyStatic.perAgenda27 }}명</td>
                        </tr>
                        <tr>
                            <td>정서적 소진/무기력</td>
                            <td>{{ this.companyStatic.agenda28 }}회 / {{ this.companyStatic.perAgenda28 }}명</td>
                        </tr>
                        <tr>
                            <td>감정조절/분노</td>
                            <td>{{ this.companyStatic.agenda29 }}회 / {{ this.companyStatic.perAgenda29 }}명</td>
                        </tr>
                        <tr>
                            <td>성격문제</td>
                            <td>{{ this.companyStatic.agenda30 }}회 / {{ this.companyStatic.perAgenda30 }}명</td>
                        </tr>
                        <tr>
                            <td>자존감/삶의 의미</td>
                            <td>{{ this.companyStatic.agenda31 }}회 / {{ this.companyStatic.perAgenda31 }}명</td>
                        </tr>
                        <tr>
                            <td>건강/불면증</td>
                            <td>{{ this.companyStatic.agenda32 }}회 / {{ this.companyStatic.perAgenda32 }}명</td>
                        </tr>
                        <tr>
                            <td>성/이성관계</td>
                            <td>{{ this.companyStatic.agenda33 }}회 / {{ this.companyStatic.perAgenda33 }}명</td>
                        </tr>
                        <tr>
                            <td>자해/자살</td>
                            <td>{{ this.companyStatic.agenda34 }}회 / {{ this.companyStatic.perAgenda34 }}명</td>
                        </tr>
                         <tr>
                            <td>중독</td>
                            <td>{{ this.companyStatic.agenda35 }}회 / {{ this.companyStatic.perAgenda35 }}명</td>
                        </tr>
                        <tr>
                            <td>기타</td>
                            <td>{{ this.companyStatic.agenda36 }}회 / {{ this.companyStatic.perAgenda36 }}명</td>
                        </tr>

                        <tr>
                            <th rowspan="5">가정자녀</th>
                            <td>가정폭력/불화/해체</td>
                            <td>{{ this.companyStatic.agenda37 }}회 / {{ this.companyStatic.perAgenda37 }}명</td>
                        </tr>
                        <tr>
                            <td>부부갈등</td>
                            <td>{{ this.companyStatic.agenda38 }}회 / {{ this.companyStatic.perAgenda38 }}명</td>
                        </tr>
                        <tr>
                            <td>자녀문제</td>
                            <td>{{ this.companyStatic.agenda39 }}회 / {{ this.companyStatic.perAgenda39 }}명</td>
                        </tr>
                        <tr>
                            <td>관계갈등(부모/형제/자매/친척)</td>
                            <td>{{ this.companyStatic.agenda40 }}회 / {{ this.companyStatic.perAgenda40 }}명</td>
                        </tr>
                        <tr>
                            <td>기타</td>
                            <td>{{ this.companyStatic.agenda41 }}회 / {{ this.companyStatic.perAgenda41 }}명</td>
                        </tr>

                        <tr>
                            <th rowspan="3">기타</th>
                            <td>법률</td>
                            <td>{{ this.companyStatic.agenda42 }}회 / {{ this.companyStatic.perAgenda42 }}명</td>
                        </tr>
                        <tr>
                            <td>재무</td>
                            <td>{{ this.companyStatic.agenda43 }}회 / {{ this.companyStatic.perAgenda43 }}명</td>
                        </tr>
                        <tr>
                            <td>기타</td>
                            <td>{{ this.companyStatic.agenda44 }}회 / {{ this.companyStatic.perAgenda44 }}명</td>
                        </tr>
                        <tr>
                            <th>미설정</th>
                            <td>-</td>
                            <td>{{ this.companyStatic.agendaNone }}회 / {{ this.companyStatic.perAgendaNone }}명</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div> 
      </div>
      <dangerReportList :onModal.sync="isModal" :onStartDate="modalStrDate" :onEndDate="modalEndDate" :onIdxCrmCenter="idxCrmCenter" :onIdxCrmCompany="idxCrmCompany" :onIdxMetaDanger="metaDanger"></dangerReportList>
  </div>
</template>

<script>
import FileSaver from 'file-saver';
import dangerReportList from '@/components/modal/dangerReportList';
export default {
    components:{
        'dangerReportList': dangerReportList
    },
    props:{
        companyId:{
            type: Number,
            default: -1
        }
    },
    data(){
        return{
            startYY: 2020,
            startMM: 1,
            endYY: 2020,
            endMM: 1,
            companyList: [],
            centerList:[],//센터 리스트
            idxCrmCompany: -1,
            idxCrmCenter:-1,
            companyStatic:{},
            noData : false,
            companyName : '전체',
            loading:true,
            isModal:false,
            modalStrDate:'',
            modalEndDate:'',
            metaDanger:-99,
        }
    },
    watch: {
        idxCrmCompany(newVal,oldVal){
            if(newVal !== oldVal){
                this.changeCompany();
            }
        }
    },
    created(){
        let date = new Date();
        this.startYY = date.getFullYear();
        this.startMM = date.getMonth() + 1;
        this.endYY = date.getFullYear();
        this.endMM = date.getMonth() + 1;
        this.idxCrmCompany = this.companyId;

        this.getCompanyListAll();
        this.getCenterListAll();

        //기업별 통계
        this.getCompanyStatistic();
    },
    methods: {
        /**
         * 현재 년도에 따라서 120년만큼 selectbox
         */
        parseYear() {
            let nowYear = new Date().getFullYear();
            let parseYear = [];
            for (let i = 0; i <= 120; i++) {
                parseYear.push(nowYear - i);
            }
            return parseYear;
        },

        /**
         * 소속리스트(기관리스트)
         */
        getCompanyListAll() {
            this.axios.get('/api/v1/company/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.companyList = res.data.companyList;

                         this.changeCompany();
                    } else {
                        //alert('소속리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        /**
         * 기업별 통계
         */
        getCompanyStatistic(){
            let tempMM = "";
            // 날짜 데이터 파싱
            if (this.startMM < 10) {
                tempMM = "0" + this.startMM
            } else {
                tempMM = this.startMM
            }
            let startDate = this.startYY.toString() + '-' + tempMM

            if (this.endMM < 10) {
                tempMM = "0" + this.endMM
            } else {
                tempMM = this.endMM
            }
            let endDate = this.endYY.toString() + '-' + tempMM

            let params = {
                idxCrmCompany: this.idxCrmCompany,
                idxCrmCenter: this.idxCrmCenter,
                startDate: startDate,
                endDate: endDate
            }

            /**
             * 화면 상단 기업별 통계 - {회사명}
             */
            this.loading = true;
            this.axios.get('/api/v1/cons/report/company_static',{
                params:params
            })
            .then((res)=>{
               this.companyStatic = res.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            }).finally(()=>{
                this.loading = false;
            });
        },

        /**
         * 검색조건 기업명
         */
        changeCompany(){
            if(parseInt(this.idxCrmCompany)  === -1){
                this.companyName = '전체';
            }else{
                const company = this.companyList.filter(v => v.idx === this.idxCrmCompany);
                if(company.length > 0){
                    this.companyName = company[0].name;
                }else{
                    this.companyName = '전체';
                }
                
            }
        },

        /**
         * 엑셀 다운로드
         */
        excelDownload(){
            let tempMM = "";
            // 날짜 데이터 파싱
            if (this.startMM < 10) {
                tempMM = "0" + this.startMM
            } else {
                tempMM = this.startMM
            }
            let startDate = this.startYY.toString() + '-' + tempMM

            if (this.endMM < 10) {
                tempMM = "0" + this.endMM
            } else {
                tempMM = this.endMM
            }
            let endDate = this.endYY.toString() + '-' + tempMM

            let params = {
                idxCrmCompany: this.idxCrmCompany,
                startDate: startDate,
                endDate: endDate
            }

            this.axios.get('/api/v1/cons/report/company_static_excel',{
                params:params,
                responseType: 'arraybuffer'
            })
            .then((res)=>{
               FileSaver.saveAs(new Blob([res.data]), "companyStatic.xlsx");
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },

         /**
         * @description : 센터리스트 전체 
         */
        getCenterListAll() {
            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList;
                    } else {
                        this.centerList = res.data.centerList;
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },

        /**
         * @description : 위험단계 별 상담일지 
         */
        openDangerListModal(pMetaDagnger){
            this.isModal = true;
            this.metaDanger = pMetaDagnger;
            this.modalStrDate = `${String(this.startYY)}-${String(this.startMM)}-01`;
            this.modalEndDate = this.formatDate(String(this.endYY),String(this.endMM));
            
            
        },

        /**
         * @description : 날짜 형식 변경 yyyy-mm => yyyy-mm-dd
         */
        formatDate(yyyy,mm){
            const lastDate = new Date(yyyy,mm,0).getDate();
            return `${yyyy}-${mm}-${String(lastDate)}`
        }

    }
}
</script>

<style>
.title{
    font-size: 27px !important;
}
.item-title{
    font-size: 18px;
    font-weight: bold;
}
</style>