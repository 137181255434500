<template>
    <!-- 상태 변경 팝업 -->
    <transition name="modal" appear>
        <div class="modal modal-overlay" v-if="onModal" @click.self="handleWrapperClick">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <p class="bold dp-inblock h-30px font-23">상담일지</p>
                    <div class="con">
                        <div class="con_table mt-20">
                                <table class="default_table mt-20">
                                    <tbody>
                                        <tr>
                                            <th width="3%">No</th>
                                            <th width="8%">일지등록날짜</th>
                                            <th width="6%">상담센터</th>
                                            <th width="6%">상담사</th>
                                            <th width="10%">고객</th>
                                            <th width="9%">유형/기업명</th>
                                            <th width="7%">예약 시작 <br>및 종료시간</th>
                                            <th width="8%">상담/검사 <br> 구분</th>
                                            <th width="7%">위험단계</th>                                            
                                            <th width="5%">상담 <br> 구분</th>
                                            <th width="18%">상담주제</th>
                                            <th width="5%">상담 <br> 현황</th>
                                            <th width="12%">관리</th>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="loading">
                                        <tr>
                                            <td colspan="99">검색중입니다.</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else-if="reportList.length === 0">
                                        <tr >
                                            <td colspan="99">검색결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(item,index) in reportList" :key="'report'+index" >
                                            <td> {{ item.rownum }} </td>
                                            <td> {{ item.consDate2 }} </td>
                                            <td> {{ item.centerName }} </td>
                                            <td> {{ item.staffName }} </td>
                                            <td> {{ item.clientName }} </td>
                                            <td class="left"> {{ item.companyName }} </td>
                                            <td> {{ item.consTime }} </td>
                                            <td> {{ item.type }} </td>
                                            <td> {{ item.dangerStepTitle }} </td>                                            
                                            <td> {{ item.titleType }} </td>
                                            <td> {{ item.titleAgendaFirst }} / {{ item.titleAgendaSecond }}</td>
                                            <td> {{ item.reservationStatus }} </td>
                                            <td :class="item.reservationStatus!=='취소' && item.reportYn === '미등록' ? 'warning' : 'white'">
                                                <!-- 미등록일 경우 -->
                                                <span v-if="item.reportYn === '미등록'">미등록</span>
                                                <!-- 등록일 경우 -->
                                                <div v-else>
                                                    <span class="underline pointer" @click="detail(item.idxReservation)">상세보기</span>
                                                    |
                                                    <span class="underline pointer" @click="requestModify(item)">수정요청</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>

                        <div class="con_table">
                            <div class="page">
                                <ul>
                                    <li>
                                        <a class="pointer" @click="prevPaging()"> &lt; </a>
                                    </li>

                                    <li class="txt-blue" v-for="index in calcMaxPaging()" :key="'paging'+index">
                                        <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                            <a :class="{'bold': index === pageNum}">{{index}}</a>
                                        </span>
                                    </li>

                                    <li>
                                        <a class="pointer" @click="nextPaging()"> &gt; </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props:{
        onModal:{
            type: Boolean,
            require: true,
            default: false
        },
        onStartDate:{
            type: String,
            require: true,
            default:''
        },
        onEndDate:{
            type: String,
            require: true,
            default:''
        },
        onIdxCrmCenter:{
             type: Number,
             require: true,
             default:-1
        },
        onIdxCrmCompany:{
             type: Number,
             require: true,
             default:-1
        },
        onIdxMetaDanger:{
             type: Number,
             require: true,
             default:-1
        }
    },
    data() {
        return {
            reportList: [],
            loading:false,
            listLength:0, //리스트 size
            maxPagingCount: 1, // 최대 페이지 수
            maxPagePart: 1, // 최대 파트가 몇번째인지
            pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
            pageNum: 1,
            pageSize: 10,
        }
    },
    mounted () {
        console.log('mounted');
    },
    watch: {
        onModal(cur) {
            if(cur === true){
                //api 태우기
                this.getReportList();
            }
        }
    },
    methods: {
        /**
         * @description 모달 종료
         */
        handleWrapperClick() {
            this.$emit('update:onModal', false);
        },
        /**
         * @description 상담일지 리스트
         */
        getReportList(){
            this.loading = true;
            const params ={
                    startDate: this.onStartDate,
                    endDate: this.onEndDate,
                    idxCrmCompany: this.onIdxCrmCompany,
                    idxCrmCenter: this.onIdxCrmCenter,
                    idxCrmMetaDanger: this.onIdxMetaDanger,
                    pageNum: this.pageNum,
                    pageSize:this.pageSize,
            }
            this.axios.get('/api/v1/cons/report/list',{
                params: params
            }).then((res)=>{
                const { err, reportList,reportListCount } = res.data;
                if(err === 0){
                     this.reportList = reportList;
                     this.listLength = reportListCount;
                     this.maxPagingCount = Math.ceil(this.listLength/ this.pageSize);
                     this.pagePart = Math.ceil(this.pageNum / 10);
                }
                else{
                    this.reportList = [];
                    this.listLength = 0;
                    this.maxPagingCount = 1;
                    this.pagePart = 1;
                    this.maxPagePart = 1;
                }
            }).finally(()=>{
                this.loading = false;
            })
        },
        /**
         * @description 이전 페이징
         */
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getReportList()
            }
        },

         /**
          * @description 현재 페이지의 페이지 범위 계산
          */
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray;
        },

        /**
         * @description : 현재 페이지 설정
         */
        paging(index){
            this.pageNum = index;
            this.getReportList();
        },

        /**
         * @description : 다음 페이징
         */
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false;
            } else {
                this.pageNum += 1;
                this.getReportList();
            }
        },

        /**
         * @description : 상담일지상세보기 페이지로 이동
         */
        detail(idx) {
            window.open(`${document.location.origin}/origin/counselor_journey_info?idx=${idx}`);
        },

        /**
         * @description : 수정요청
         */
        requestModify(item){
            if(confirm(`"${item.staffName}"에게 수정요청 하시겠습니까?`)){
                this.axios.put(`/api/v1/cons/report-request-modi/${item.idxReport}/Y`)
                .then((res)=>{
                    if(res.data.err === 0){
                        alert(`"${item.staffName}"에게 수정요청 하였습니다.`);
                    }else{
                        alert(`"${item.staffName}"에게 수정요청을 실패하였습니다.`);
                    }
                }).catch(err => {
                    console.error(err)
                    alert(err);
                })
            }
        },



    },
}
</script>

<style lang="scss">
@import "@/assets/css/modal.scss";
  .warning
  {
    background-color: #F7DEEB;
  }
</style>